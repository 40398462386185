<template>
  <b-card>
    <b-modal
      id="add-update-document-modal"
      ref="modal"
      no-close-on-backdrop
      :title="editData? 'Edit Document Type' : 'Add Document Type'"
      :ok-title="editData? 'Update' : 'Save'"
      cancel-variant="outline-secondary"
      @ok="formSubmit"
    >
      <form
        ref="form"
        @submit.stop.prevent="addDocumentType"
      >
        <!-- Add Document -->
        <b-row>
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-row>
              <b-col>
                <b-form-group
                  label="Document Type Name"
                  label-for="name"
                  invalid-feedback="Document type is required"
                  :state="documentTypeState"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="name"
                    placeholder="Document Type"
                    type="text"
                    :state="documentTypeState"
                    required
                    @keyup="checkSpace"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Document for"
                  label-cols-md="4"
                >
                  <b-form-radio-group
                    v-model="document_for"
                    class="mt-0"
                    :options="fintechEmployeeOptions"
                    :state="documentForState"
                    name="radio-inline1"
                    required
                  />
                  <b-form-invalid-feedback :state="documentForState">
                    Document for is required
                  </b-form-invalid-feedback>
                  <b-form-valid-feedback :state="documentForState" />
                </b-form-group>
              </b-col>
              <!-- <b-col>
                <b-form-group
                  label="Document for"
                  label-for="document_for"
                  invalid-feedback="Document for is required"
                  :state="documentForState"
                  label-cols-md="5"
                >
                  <b-form-radio
                    v-model="document_for"
                    value="1"
                    :state="documentForState"
                    required
                  >
                    Student
                  </b-form-radio>
                  <b-form-radio
                    v-model="document_for"
                    value="2"
                    :state="documentForState"
                    required
                  >
                    Employee
                  </b-form-radio>
                </b-form-group>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Status"
                  label-cols-md="4"
                >
                  <b-form-radio-group
                    v-model="status"
                    class="mt-0"
                    :options="fintechStatusOptions"
                    name="radio-inline2"
                    :state="statusState"
                  />
                  <b-form-invalid-feedback :state="statusState">
                    Status is required
                  </b-form-invalid-feedback>
                  <b-form-valid-feedback :state="statusState" />
                </b-form-group>
              </b-col>
              <!-- <b-col>
                <b-form-group
                  label="Status"
                  label-for="Status"
                  invalid-feedback="Status is required"
                  :state="statusState"
                  label-cols-md="5"
                >
                  <b-form-radio
                    v-model="status"
                    value="1"
                    :state="statusState"
                    required
                  >
                    Active
                  </b-form-radio>
                  <b-form-radio
                    v-model="status"
                    value="0"
                    :state="statusState"
                    required
                  >
                    Inactive
                  </b-form-radio>
                </b-form-group>
              </b-col> -->
            </b-row>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          v-b-modal.add-update-document-modal
          variant="outline-primary"
          @click="resetForm"
        >
          Add Document Type
        </b-button>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="documents"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: sl -->
        <span v-if="props.column.field === 'sl'">
          <span class="d-flex">
            <p>{{ props.index + 1 }}</p>
          </span>
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <span class="d-flex">
            <p v-if="props.row.status == 1">Active</p>
            <p v-else>Inactive</p>
          </span>
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'document_for'">
          <span class="d-flex">
            <p v-if="props.row.document_for == 1">Student</p>
            <p v-else>Employee</p>
          </span>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteDocumentType(props.row)"
            >Delete</b-button>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30','40','50']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>
<script>
import {
  BCard,
  BPagination,
  BFormInput,
  // BFormRadio,
  BFormGroup,
  BFormSelect,
  BRow,
  BCol,
  VBModal,
  BButton,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BFormInput,
    // BFormRadio,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BFormValidFeedback,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      name: '',
      document_for: '',
      status: '',
      id: '',
      documentTypeState: null,
      documentForState: null,
      statusState: null,
      editData: false,
      columns: [
        {
          label: 'SL',
          field: 'sl',
        },
        {
          label: 'Document Type',
          field: 'name',
        },
        {
          label: 'Document For',
          field: 'document_for',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      documents: [],
      fintechStatusOptions: [
        { text: 'Active', value: 1 },
        { text: 'Inactive', value: 0 },
      ],
      fintechEmployeeOptions: [
        { text: 'Student', value: 1 },
        { text: 'Employee', value: 2 },
      ],
    }
  },
  mounted() {
    this.getResourceDocumentTypes()
  },
  methods: {
    getResourceDocumentTypes() {
      this.$http.get(`${window.apiUrl}resource-sharing/get-resource-document-types`)
        .then(res => {
          this.documents = res.data.data
        })
    },
    checkFormValidity() {
      this.documentTypeState = null
      this.documentForState = null
      this.statusState = null
      if (this.name == null || this.name === '') {
        this.documentTypeState = false
      }
      if (this.document_for == null || this.document_for === '') {
        this.documentForState = false
      }
      if (this.status == null || this.status === '') {
        this.statusState = false
      }
      if (this.documentTypeState !== false && this.documentForState !== false && this.statusState !== false) {
        return true
      }
      return false
    },
    formSubmit(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      if (this.editData) {
        this.updateDocumentType()
      } else {
        this.addDocumentType()
      }
    },
    checkSpace() {
      if (this.name === ' ') {
        this.name = ''
      }
    },
    addDocumentType() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.$http.post(`${window.apiUrl}resource-sharing/add-document-type`, {
        name: this.name,
        document_for: this.document_for,
        status: this.status,
      })
        .then(res => {
          if (res.data.status) {
            this.getResourceDocumentTypes()
            this.resetForm()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('add-update-document-modal')
            })
            this.$swal({
              icon: 'success',
              title: 'Added!',
              text: 'Document Added successfully',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateDocumentType() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.$http.put(`${window.apiUrl}resource-sharing/update-document-type/${this.id}`, {
        name: this.name,
        document_for: this.document_for,
        status: this.status,
      })
        .then(res => {
          if (res.data.status) {
            this.getResourceDocumentTypes()
            this.resetForm()
            this.$nextTick(() => {
              this.$bvModal.hide('add-update-document-modal')
            })
            this.$swal({
              icon: 'success',
              title: 'Updated!',
              text: 'Document Type updated successfully',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteDocumentType(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'danger',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`${window.apiUrl}resource-sharing/delete-document-type/${rowData.id}`)
            .then(res => {
              if (res.data.status) {
                this.getResourceDocumentTypes()
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Document Type deleted successfully',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },

    editRow(rowData) {
      this.resetForm()
      this.id = rowData.id
      this.name = rowData.name
      this.document_for = rowData.document_for
      this.status = rowData.status
      this.editData = true
      this.$nextTick(() => {
        this.$bvModal.show('add-update-document-modal')
      })
    },
    resetForm() {
      this.documentTypeState = null
      this.documentForState = null
      this.statusState = null
      this.editData = false
      this.name = ''
      this.document_for = ''
      this.status = ''
      this.id = ''
    },
  },
}
</script>
